<template>
  <div class="staff-members">
    <lenon-list-page
      title="Staff Attendance"
      :columns="columns"
      :rows="attendances"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :show-profile-photo="true"
      search-placeholder="Search..."
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="largeScreen"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-date-picker
            v-model="date"
            label="Attendance Date"
            class="mr-1"
          />
          <lenon-select
            v-model="reportingTime"
            label="Reporting Time"
            class="mr-1"
            :options="reportingTimes"
          />
        </div>
        <div
          v-responsive="smallScreen"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.attendance
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="col-12">
          <b-collapse
            id="attendance"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-date-picker
                  v-model="date"
                  label="Attendance Date"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="reportingTime"
                  label="Reporting Time"
                  :options="reportingTimes"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template #status="{ row }">
        <b-badge :variant="row.item.is_present ? 'light-success' : 'light-danger'">
          <strong v-if="!row.item.reported_time">Not Recorded</strong>
          <strong v-else>{{ row.item.is_present ? 'Present' : 'Absent' }}</strong>
        </b-badge>
      </template>
      <template #punctuality="{ row }">
        <b-badge
          :variant="row.item.punctuality === 'On Time' || row.item.punctuality === 'N/A' ? 'light-success' : 'light-danger'"
        >
          <strong>
            {{ row.item.punctuality }}
          </strong>
        </b-badge>
      </template>
      <template #reported_time="{ row }">
        <strong v-if="row.item.is_present">
          {{ row.item.reported_time }}
        </strong>
        <b-badge
          v-else
          variant="light-success"
        >
          <strong>N/A</strong>
        </b-badge>
      </template>
    </lenon-list-page>
    <location-setup
      :modal-opened="locationModalOpened"
      @modalClosed="closeModal('location')"
    />
  </div>
</template>

<script>
import {
  BBadge, VBTooltip, BCollapse, VBToggle,
} from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import { STAFF_LOCATIONS_Q, STAFF_ATTENDANCES_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LocationSetup from '@/views/staff/attendance/LocationSetup.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'

export default {
  name: 'StaffMemberList',
  components: {
    LocationSetup,
    LenonSelect,
    LenonDatePicker,
    LenonListPage,
    BBadge,
    LenonButton,
    BCollapse,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      locationModalOpened: false,
      tableLoading: false,
      date: null,
      reportingTime: null,
      reportingTimes: [
        {
          label: '06:00 AM',
          value: '06:00',
        },
        {
          label: '06:30 AM',
          value: '06:30',
        },
        {
          label: '07:00 AM',
          value: '07:00',
        },
        {
          label: '07:30 AM',
          value: '07:30',
        },
        {
          label: '08:00 AM',
          value: '08:00',
        },
        {
          label: '08:30 AM',
          value: '08:30',
        },
        {
          label: '09:00 AM',
          value: '09:00',
        },
        {
          label: '09:30 AM',
          value: '09:30',
        },
        {
          label: '10:00 AM',
          value: '10:00',
        },
        {
          label: '10:30 AM',
          value: '10:30',
        },
      ],
    }
  },
  computed: {
    smallScreen() {
      return this.$store.getters['app/smallScreen']
    },
    largeScreen() {
      return this.$store.getters['app/largeScreen']
    },
    isLargeScreen() {
      return this.$store.getters['app/isLargeScreen']
    },
    attendances() {
      let attendances = this.$store.getters['staff/attendances'] || []
      attendances = attendances.map(att => ({
        ...att,
        punctuality: this.getPunctuality(this.reportingTime, att.reported_time),
      }))
      return attendances
    },
    attendanceSettings() {
      return this.$store.getters['staff/attendanceSettings']
    },
    columns() {
      const ls = this.isLargeScreen
      return [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'name',
          label: 'Staff Name',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Attendance Status',
          sortable: true,
        },
        {
          key: 'absence_reason',
          label: 'Absence Reason',
        },
        {
          key: 'reported_time',
          label: 'Reported Time',
          sortable: ls,
        },
        {
          key: 'punctuality',
          label: 'Punctuality',
          sortable: ls,
        },
      ]
    },
  },
  watch: {
    date(date) {
      if (date) {
        this.$store.commit('staff/setAttendanceSettings', { ...this.attendanceSettings, date })
        this.fetchStaffAttendances()
      }
    },
    reportingTime(reportingTime) {
      if (reportingTime) {
        this.$store.commit('staff/setAttendanceSettings', { ...this.attendanceSettings, reportingTime })
      }
    },
  },
  mounted() {
    this.date = this.attendanceSettings.date
    this.reportingTime = this.attendanceSettings.reportingTime
    this.fetchLocations()
  },
  methods: {
    getPunctuality(reportingTime, reportedTime) {
      if (!reportedTime || !reportingTime) {
        return 'N/A'
      }
      // reporting time is in format 03:38
      // split time looks like ['03','38']
      const time1 = reportingTime.split(':')

      // reported time is in format 03:38 AM
      // remove AM/PM, trim and split = ['03','38']
      const time2 = reportedTime.replace('AM', '').replace('PM', '').trim().split(':')
      if (reportedTime.includes('PM')) {
        time2[0] = +time2[0] + 12
        time2[1] = +time2[1] + 12
      }
      if ((+time1[0] === +time2[0] && +time1[1] === +time2[1]) || (+time1[0] > +time2[0]) || (+time1[0] === +time2[0] && +time1[1] > +time2[1])) {
        return 'On Time'
      }
      return 'Late'
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'location':
          this.locationModalOpened = false
          break
      }
    },
    showLocationModal() {
      this.locationModalOpened = true
    },
    fetchStaffAttendances() {
      this.tableLoading = true
      this.$apollo.query({ query: STAFF_ATTENDANCES_Q, variables: { date: this.date } })
        .then(res => {
          this.$store.commit('staff/setStaffAttendances', res.data.staffAttendances)
        }).finally(() => {
          this.tableLoading = false
        })
    },
    fetchLocations() {
      this.$apollo.query({ query: STAFF_LOCATIONS_Q })
        .then(res => {
          this.$store.commit('staff/setLocations', res.data.staffLocations)
        }).finally(() => {
        })
    },
  },
}
</script>
