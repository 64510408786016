<template>
  <!--    Location Form-->
  <validation-observer
      ref="locationForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateLocationMode?'Update':'Create'} Location`"
        :show="locationModalOpened"
        :show-overlay="deletingLocation"
        @onClose="closeLocationModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedLocationId"
              placeholder="All Locations"
              :options="locations"
              label-name="title"
              value-name="id"
              @input="populateLocationForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteLocation()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetLocationForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
          v-model="location.title"
          name="title"
          placeholder="eg. Staff Common Room"
          rules="required"
          label="Title"
      />
     <div class="row mb-1">
       <div class="col">
         <b-badge
             variant="light-success"
         >
           <strong>Latitude: </strong> <span>{{ location.latitude || 'N/A' }}</span>
         </b-badge>
       </div>
       <div class="col">
         <b-badge
             variant="light-success"
         >
           <strong>Longitude: </strong> <span>{{ location.longitude || 'N/A' }}</span>
         </b-badge>
       </div>
     </div>
      <lenon-button @onClick="getCurrentLocation()" class="mb-2" label="Get Current Location" icon="MapPinIcon"/>

      <div class="mb-5">
        <lenon-multi-select
            v-model="location.staff_members"
            placeholder="Affected Staff Members"
            label="Affected Staff Members"
            rules="required"
            :options="staffMembers"
            label-name="name"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeLocationModal()"
          />
          <lenon-button
              :label="updateLocationMode?'Update':'Create'"
              :disabled="invalid"
              :loading="locationLoading"
              loading-text="Loading..."
              @onClick="updateLocationMode?updateLocation():createLocation()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider,BBadge } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_LOCATION_M, DELETE_LOCATION_M, UPDATE_LOCATION_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'

export default {
  name: 'LocationSetup',
  components: {
    LenonMultiSelect,
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      locationModalOpened: false,
      updateLocationMode: false,
      locationLoading: false,
      deletingLocation: false,
      selectedLocationId: null,
      location:{
        id: null,
        title: null,
        latitude: null,
        longitude: null,
        staff_members: null,
      },
    }
  },
  computed: {
    staffMembers(){
      return this.$store.getters['staff/members'].map(u => ({
        ...u,
        name: `${u.first_name} ${u.last_name}`,
      }))
    },
    selectedLocation() {
      return this.locations.find(ter => ter.id === this.selectedLocationId)
    },
    locations() {
      return this.$store.getters['staff/locations']
    },
  },
  watch: {
    modalOpened(opened) {
      this.locationModalOpened = opened
    },
  },
  methods: {
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position=>{
          this.location.latitude = position.coords.latitude
          this.location.longitude = position.coords.longitude
        }, (error)=>{

          switch(error.code) {
            case error.PERMISSION_DENIED:
              this.showInfo("You have denied the request for Geolocation.")
              break;
            case error.POSITION_UNAVAILABLE:
              this.showInfo("Location information is unavailable.")
              break;
            case error.TIMEOUT:
              this.showInfo("The request to get your location timed out.")
              break;
            case error.UNKNOWN_ERROR:
              this.showInfo("An unknown error occurred.")
              break;
          }
        });
      } else {
        this.showInfo("Geolocation is not supported by this browser.")
      }
    },
    resetLocationForm() {
      this.selectedLocationId = null
      this.updateLocationMode = false
      this.location = {
        id: null,
        title: null,
        latitude: null,
        longitude: null,
        staff_members: null,
      }
      this.$refs.locationForm.reset()
    },
    populateLocationForm(location) {
      if (this.selectedLocationId) {
        this.updateLocationMode = true
        this.location = {...this.selectedLocation,staff_members:this.selectedLocation.staff_members.map((id)=>this.staffMembers.find((st)=>st.id===id))}
      } else {
        this.resetLocationForm()
      }
    },
    closeLocationModal() {
      this.locationModalOpened = false
      this.$emit('modalClosed')
    },
    updateLocation() {
      this.error = {}
      if (!this.selectedLocationId) {
        return
      }
      const location = {
        ...this.location,
        staff_members: this.location.staff_members.map((st)=>st.id),
      }
      this.locationLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_LOCATION_M,
        variables: { input: location },
      }).then(res => {
        this.showSuccess('Updated location successfully')
        this.locationLoading = false
        this.$store.commit('staff/updateLocation', res.data.updateLocation)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update location')
        this.locationLoading = false
      })
    },
    createLocation() {
      this.error = {}
      this.locationLoading = true
      const location = {
          ...this.location,
        staff_members: this.location.staff_members.map((st)=>st.id),
      }
      this.$apollo.mutate({
        mutation: CREATE_LOCATION_M,
        variables: { input: location },
      }).then(res => {
        this.showSuccess('Created location successfully')
        this.locationLoading = false
        this.$store.commit('staff/addLocation', res.data.createLocation)
        this.resetLocationForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.locationLoading = false
        this.showError('Failed to create location')
      })
    },
    deleteLocation() {
      if (!this.selectedLocationId) {
        this.showInfo('Please select a location')
        return
      }
      this.deletingLocation = true
      this.$apollo.mutate({ mutation: DELETE_LOCATION_M, variables: { id: this.selectedLocationId } }).then(() => {
        this.deletingLocation = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('staff/removeLocation', this.selectedLocationId)
        this.selectedLocationId = null
        this.resetLocationForm()
      }).catch(() => {
        this.showError('Failed to delete location')
        this.deletingLocation = false
      })
    },

  },
}
</script>
